import React from "react";
import { graphql } from "gatsby";
import BlockContent from "@sanity/block-content-to-react";

import Layout from "../components/layout";

import "../styles/basic-page.scss";

const AboutPage = ({ data }) => {
  const pageData = data.sanityAbout;

  return (
    <Layout pageTitle="About">
      <div className="text">
        <BlockContent blocks={pageData._rawText} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    sanityAbout(_id: { eq: "single-about" }) {
      _rawText
    }
  }
`;

export default AboutPage;
